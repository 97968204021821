import React from "react"
import { graphql } from "gatsby"
import { getCurrentHomepage } from "@bufox/gatsby-utils/fe/wp"
import PropTypes from "prop-types"
import Layout from "components/layouts/base-layout/base-layout"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import PostCardList from "components/post-card-list/post-card-list"
import MainContent from "components/main-content/main-content"
import styled from "styled-components"

const Post = props => {
  const {
    className,
    location,
    data: {
      singlePost: {
        content,
        dateGmt,
        excerpt,
        featuredImage,
        language,
        modifiedGmt,
        seo,
        title,
        translations,
        relatedPosts,
      },
      homepages,
      rawMenus,
      searchUrls,
      wpSettings,
      commonThemeSettings,
      themeSettings: {
        acfThemeSettings: {
          post: { relatedPostsText },
        },
      },
    },
    // pageContext: { googleServices },
  } = props
  const {
    acfThemeSettings: {
      generalSettings,
      footer,
      search,
      socialNetworks,
      socialNetworksShare,
    },
  } = commonThemeSettings

  const homepage = getCurrentHomepage(homepages, language.code)

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={homepage?.uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={excerpt}
          publishDate={dateGmt}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={title}
          translations={translations}
        />
        <MainContent
          breadcrumbs={{ breadcrumbs: seo.breadcrumbs }}
          className="post-content"
          content={content}
          date={{
            dateGmt: dateGmt,
            modifiedGmt: modifiedGmt,
            locale: language?.locale?.replace("_", "-"),
          }}
          image={featuredImage ? featuredImage.node : null}
          location={location}
          siteUrl={process.env.GATSBY_SITE_URL}
          socialNetworksShare={socialNetworksShare}
          title={title}
        ></MainContent>
        {relatedPosts.nodes.length ? (
          <aside className="related-posts dry-container">
            {relatedPostsText ? (
              <p className="related-posts-text">{relatedPostsText}</p>
            ) : null}
            <PostCardList
              class="related-posts-post-card-list"
              postsList={relatedPosts.nodes}
            ></PostCardList>
          </aside>
        ) : null}
      </div>
    </Layout>
  )
}

Post.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    homepages: PropTypes.object,
    rawMenus: PropTypes.object,
    singlePost: PropTypes.shape({
      content: PropTypes.string,
      dateGmt: PropTypes.string,
      excerpt: PropTypes.string,
      featuredImage: PropTypes.object,
      language: PropTypes.object,
      modifiedGmt: PropTypes.string,
      relatedPosts: PropTypes.object,
      seo: PropTypes.object,
      slug: PropTypes.string,
      title: PropTypes.string,
      translations: PropTypes.array,
      uri: PropTypes.string,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
    themeSettings: PropTypes.object,
  }),
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

const StyledPost = styled(Post)`
  .related-posts {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 4rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      height: 4px;
      background: var(--secondary);
      content: "";
    }
  }
  .related-posts-text {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
  }
`

export const pageQuery = graphql`
  query Post(
    $id: String
    $themeSettingsPageId: String
    $menusLocations: [WpMenuLocationEnum]
  ) {
    singlePost: wpPost(id: { eq: $id }) {
      ...PostInfo
      relatedPosts {
        nodes {
          ...DryPostCardInfo
        }
      }
    }
    themeSettings: wpThemeSettingsPage(id: { eq: $themeSettingsPageId }) {
      acfThemeSettings {
        post {
          relatedPostsText
        }
      }
    }
    ...CommonThemeSettings
    ...Homepages
    ...LocalizedMenus
    ...SearchUrls
    ...WpSettings
  }
`

export default StyledPost

export { Post }
