import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { withPrefix } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import themeConfig from "utils/theme-config"

const { screens } = themeConfig

const PostCardList = props => {
  const {
    className,
    enableLoadMore,
    nFirstPagePosts,
    nMorePostsToLoad,
    postsList,
    loadMorePostsFilterCallback,
    loadMoreText,
  } = props
  const [posts, setPosts] = useState(postsList)
  const [nSkipResults, setNSkipResults] = useState(0)
  const [loadedJsonPages, setLoadedJsonPages] = useState(0)
  const [loadingPosts, setLoadingPosts] = useState(false)

  /**
   *
   * Similar to https://github.com/WalltoWall/gatsby-plugin-paginated-collection Example 2
   */
  const loadMorePosts = useCallback(
    async (nPosts = nMorePostsToLoad) => {
      if (!loadingPosts) {
        setLoadingPosts(true)
        let tempPostsBuffer = []
        let hasNextPage = true
        let currentJsonPage = loadedJsonPages
        let nCurrentSkipResults = nSkipResults
        while (tempPostsBuffer.length <= nPosts || !hasNextPage) {
          try {
            const path = withPrefix(
              `/all-posts-info/posts-short-info-${currentJsonPage}.json`
            )
            /* eslint-disable no-await-in-loop  */
            const res = await fetch(path)
            const json = await res.json()
            if (json?.posts) {
              let filteredPosts = loadMorePostsFilterCallback
                ? json.posts.filter(loadMorePostsFilterCallback)
                : json.posts
              if (nCurrentSkipResults) {
                filteredPosts = filteredPosts.slice(nCurrentSkipResults)
                nCurrentSkipResults = 0
              }
              tempPostsBuffer.push(...filteredPosts)
              if (tempPostsBuffer.length > nPosts) {
                const nExceedingPosts = tempPostsBuffer.length - nPosts
                tempPostsBuffer = tempPostsBuffer.slice(0, nPosts)
                nCurrentSkipResults = filteredPosts.length - nExceedingPosts
                break // because we don't want the page to change
              }
              if (json.hasNextPage) {
                currentJsonPage += 1
              } else {
                hasNextPage = false
                currentJsonPage = -1
                break
              }
            }
          } catch (error) {
            currentJsonPage = -1
          }
        }
        // updating states with current results
        setLoadedJsonPages(currentJsonPage)
        setNSkipResults(nCurrentSkipResults)
        setPosts(currentState => [...currentState, ...tempPostsBuffer])
        setLoadingPosts(false)
      }
    },
    [loadedJsonPages, loadingPosts, posts]
  )

  useEffect(() => {
    if (enableLoadMore && nFirstPagePosts > posts.length) {
      loadMorePosts(nFirstPagePosts - posts.length)
    }
  }, [])

  return (
    <div className={className}>
      <ul className="post-card-list dry-container">
        {posts.map((post, i) => (
          <li key={`post-${i}`}>
            {post.title && post.slug && post.uri && (
              <div className="post-card">
                <Link className="post-title" to={post.uri}>
                  {post.title}
                </Link>
                {post.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData ? (
                  <GatsbyImage
                    image={
                      post.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    className="post-image"
                    alt=""
                    loading="lazy"
                  />
                ) : null}
              </div>
            )}
          </li>
        ))}
      </ul>
      {enableLoadMore && loadedJsonPages !== -1 && (
        <div className="btn-primary load-more">
          <a onClick={() => loadMorePosts(nMorePostsToLoad)}>{loadMoreText}</a>
        </div>
      )}
    </div>
  )
}

PostCardList.propTypes = {
  className: PropTypes.string,
  colorPalette: PropTypes.array,
  enableLoadMore: PropTypes.bool,
  loadMoreText: PropTypes.string,
  nFirstPagePosts: PropTypes.number,
  nMorePostsToLoad: PropTypes.number,
  postsList: PropTypes.array,
  loadMorePostsFilterCallback: PropTypes.func,
}

PostCardList.defaultProps = {
  enableLoadMore: false,
  nMorePostsToLoad: 8,
  loadMoreText: "Load More Posts",
}

const StyledPostCardList = styled(PostCardList)`
  .post-card-list {
    li {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .post-title {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(225, 232, 237, 0.7) 0%,
      var(--light) 100%
    );
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-transform: uppercase;
    padding: 2rem;
    text-align: center;
    line-height: 2rem;
    width: 90%;
    font-weight: 400;
    text-overflow: clip;
    hyphens: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    z-index: 10;
  }

  .post-card {
    position: relative;
    height: 16rem;
  }

  .post-image {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  li:nth-child(4n + 1) .post-card {
    background-color: var(--primary);
  }

  li:nth-child(4n + 2) .post-card {
    background-color: var(--secondary);
  }

  li:nth-child(4n + 3) .post-card {
    background-color: var(--tertiary);
  }

  li:nth-child(4n + 4) .post-card {
    background-color: var(--gray4);
  }
  @media only screen and (min-width: ${screens.lg}) {
    .post-card-list {
      display: flex;
      flex-wrap: wrap;
      li {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 50%;
        margin-bottom: 4rem;
      }
    }
  }
  @media only screen and (min-width: ${screens["2xl"]}) {
    .post-card-list {
      li {
        width: 33.333333%;
      }
    }
  }
`

export default StyledPostCardList
export { PostCardList }

/* For Gatsby plugin image options: 
   https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/ */
export const PostInfoFragment = graphql`
  fragment DryPostCardInfo on WpCommonPost {
    title
    uri
    slug
    featuredImage {
      node {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export const MiniPostInfoFragment = graphql`
  fragment DryMiniPostCardInfo on WpCommonPost {
    title
    uri
    slug
    featuredImage {
      node {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, width: 50, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
